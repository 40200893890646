<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Environment</h4>
      <div class="alert alert-info">
        Application keys enable you to interact with the our cloud services in your own server, enabling you to send bulk SMS. These credentials are used for authentication.
      </div>
      <h5>Current Environment is <strong>{{ app.environment }}</strong></h5>
      <a href="#" @click.prevent="swicthEnvironment" :class="`mt-3 btn btn-${app.environment == 'live' ? 'warning' : 'success'}`">{{ app.environment == 'live' ? 'Switch to sandbox' : 'Switch to live' }}</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    swicthEnvironment() {
      this.$loader.start('main-content')

      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/environment`).then(response => {
        this.$store.dispatch('setApp', response.data.current_app)
        this.$loader.stop('main-content')
      })
    }
  },

  computed: {
    app() {
      return this.$store.getters.getApp
    }
  }
}
</script>